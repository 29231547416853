html{
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth !important;
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 7px !important;
} /* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important; 
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important; 
}
 /* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */


body {
scrollbar-face-color: #f1f1f1 !important;
margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'manage_care_semibold';
  src: url('./font/proxima_nova_semibold.woff') format('woff');
}
@font-face {
	font-family: 'manage_care_bold';
  src: url('./font/proxima_nova_bold.woff') format('woff');
}

.MuiPickersDateRangePickerInput-root{
  width: 100%;
}

/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
body {
	font-family: 'manage_care_semibold' !important;
}
code {
  font-family: 'manage_care_semibold' !important;
}
textarea:focus, input:focus{
  font-family: 'manage_care_semibold' !important;
}
input, select, textarea{
  font-family: 'manage_care_semibold' !important;
}

:fullscreen{
  z-index: 5;
}

.topnav-avatar > .MuiAvatarGroup-avatar{
  width: 27px;
  height: 27px;
  font-size: 13px;
}
.MuiPaper-elevation1 {
  box-shadow: 0 15px 23px 0 #110f4703 !important;
  border-top: 1px solid #eee !important;
}
.MuiPopover-paper{
  box-shadow: 0 15px 23px 0 #110f4703 !important;
  border: 1px solid #eee !important;
}
.MuiPaper-elevation8 {
  box-shadow: 0 22px 24px 0 rgb(17 15 71/8%)!important;
  border: 1px solid hsla(0,0%,84.7%,.5882352941176471)!important;
}

abbr{
  text-decoration: none;
}
/* abbr:hover::after {
  position: absolute;
  bottom: 100%;
  left: 100%;
  display: block;
  padding: 1em;
  background: yellow;
  content: attr(title);
  } */